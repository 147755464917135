import React from 'react';

class LzIcon extends React.Component {

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { source, name, floatRight, placeTopRight, muted, color, pointer, spin, pulse, button, size, transparent,
            primary, secondary, success, warning, info, danger, style, className, title, rounded } = this.props;

        const styles = style || {};

        if (color) {
            styles.color = color;
        }

        if (muted) {
            styles.opacity = 0.5;
        }

        if (placeTopRight) {
            styles.position = 'absolute';
            styles.top = 0;
            styles.right = 0;
        }

        if (floatRight) {
            styles.float = 'right';
        }

        if (rounded) {
            styles.borderRadius = '50%';
        }

        return (
            <i
                className={
                    'lz-icon ' + (source ? source + ' ' + source : 'fa fa') + '-' + name +
                    (size ? ' ' + (source || 'fa') + '-' + size : '') +
                    (pointer ? ' lz-cursor-pointer' : '') +
                    (spin ? ' fa-spin' : '') +
                    (pulse ? ' fa-pulse' : '') +
                    (button ? ' lz-icon-button' : '') +
                    (transparent ? ' lz-transparent' : '') +
                    (primary ? ' lz-primary' : '') +
                    (secondary ? ' lz-secondary' : '') +
                    (success ? ' lz-success' : '') +
                    (warning ? ' lz-warning' : '') +
                    (info ? ' lz-info' : '') +
                    (danger ? ' lz-danger' : '') +
                    (className ? ' ' + className : '')
                }
                style={styles}
                title={title || ''}
                onClick={() => this.onClick()}
            ></i>
        );
    }

}

export default LzIcon;
